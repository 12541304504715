var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"11","md":"6"}},[_vm._v(" "+_vm._s(_vm.value.index)+". "+_vm._s(_vm.value.target)+" ")]),_c('v-col',{staticClass:"d-flex d-md-none",attrs:{"cols":"1"}},[_c('span',{staticClass:"expandable",on:{"click":function($event){_vm.contentExpanded = !_vm.contentExpanded}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.contentExpanded),expression:"contentExpanded"}]},[_vm._v("mdi-chevron-up")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.contentExpanded),expression:"!contentExpanded"}]},[_vm._v("mdi-chevron-down")])],1)]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.value.date), 'date'))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[(_vm.statusIncluded)?[(_vm.canChangeStatus)?_c('v-select',{attrs:{"dense":"","outlined":"","items":_vm.targetStatusItems,"hide-details":""},on:{"change":_vm.saveTarget},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}):[_vm._v(" "+_vm._s(_vm.$t(("targets.targetStatus." + (_vm.value.status))))+" ")]]:_vm._e()],2),_c('v-spacer'),_c('v-col',{staticClass:"text-md-right",attrs:{"cols":"12","md":"2"}},[(!_vm.readOnly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""},on:{"click":function($event){_vm.dialogVisible = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil-outline ")])],1)],1)]}}],null,false,2517851446)},[_c('target-item-dialog',{attrs:{"visible":_vm.dialogVisible},on:{"close":function($event){_vm.dialogVisible = false},"save":function($event){return _vm.updateTarget($event)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])],1):_vm._e(),(_vm.canDelete && !_vm.readOnly)?[(_vm.value.copyOf)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""},on:{"click":function($event){return _vm.deleteTarget(_vm.value)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus-box-multiple ")])],1)]}}],null,false,3854064019)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('targets.copyTargetRevert')))])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""},on:{"click":function($event){return _vm.deleteTarget(_vm.value)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-forever")])],1)]}}],null,false,505047537)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])]]:_vm._e(),(_vm.canCopy)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""},on:{"click":function($event){return _vm.copyTarget(_vm.value)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-box-multiple")])],1)]}}],null,false,2026172729)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('targets.copyTarget'))+" ")])]):_vm._e(),(_vm.isArchived)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""},on:{"click":_vm.resetTargetStatus}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-package-up ")])],1)]}}],null,false,2485080637)},[_c('span',[_vm._v(_vm._s(_vm.$t('targets.reopen')))])]):_vm._e(),(!_vm.commentsDisabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""},on:{"click":_vm.showComments}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-message-text ")]),_vm._v(" "+_vm._s(_vm.value.comments.length)+" ")],1)]}}],null,false,695170297)},[_c('span',[_vm._v(_vm._s(_vm.$t('targets.comment')))])]):_vm._e(),_c('span',{staticClass:"expandable d-none d-md-inline",on:{"click":function($event){_vm.contentExpanded = !_vm.contentExpanded}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.contentExpanded),expression:"contentExpanded"}]},[_vm._v("mdi-chevron-up")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.contentExpanded),expression:"!contentExpanded"}]},[_vm._v("mdi-chevron-down")])],1)],2),_c('v-expand-transition',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.contentExpanded),expression:"contentExpanded"}],staticClass:"expanded-content",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"ma-0 muted"},[_vm._v(_vm._s(_vm.$tc('targets.subtargets', 2)))])])],1),(
          _vm.subtargetsAsTreeviewNodes && _vm.subtargetsAsTreeviewNodes.length > 0
        )?_c('v-treeview',{attrs:{"items":_vm.subtargetsAsTreeviewNodes,"open-all":true}}):_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('targets.noSubtargets'))+" ")])],1)],1)],1)],1)],1),(!_vm.commentsDisabled)?_c('v-expand-transition',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.contentExpanded),expression:"contentExpanded"}],ref:"comments",staticClass:"expanded-content",attrs:{"cols":"12"}},[_c('comments-table',{attrs:{"readOnly":_vm.readOnly,"canComment":_vm.canComment},on:{"save":_vm.saveTarget},model:{value:(_vm.value.comments),callback:function ($$v) {_vm.$set(_vm.value, "comments", $$v)},expression:"value.comments"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }