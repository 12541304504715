export const targetEnums = Object.freeze({
  targetStatus: {
    OPEN: 10,
    IN_PROGRESS: 20,
    FINISHED: 100,
    NOT_RELEVANT: 110,
    NOT_ACCOMPLISHED: 120
  },

  tableTypes: {
    FEEDBACK_OLD: 1,
    FEEDBACK_NEW: 2,
    COPIED: 3,
    NEW: 4,
    ARCHIVED: 5,
    REVIEW: 6
  },

  targetType: {
    DEVELOPMENT_GOAL: 1,
    ACTIONS: 2
  }
});
