<template>
  <v-row>
    <v-col cols="8">
      {{ value.comment }}
    </v-col>

    <v-col cols="2">
      {{ date }}
    </v-col>

    <v-col cols="2">
      {{ username }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    username() {
      const firstName = this.value.firstName;
      const lastName = this.value.lastName;
      return `${firstName} ${lastName}`;
    },

    date() {
      var date = null;
      try {
        date = this.$d(new Date(this.value.crdt), 'datetime');
      } catch {
        date = this.value.crdt;
      }
      return date;
    }
  },

  components: {}
};
</script>

<style lang="scss" scoped>
.overview-body .row .expandable:hover {
  cursor: pointer;
}

.overview-body .row.table-row-divider:hover {
  background: none;
}

.table .row.table-row-divider {
  margin-top: 0;
}

.table .row.table-row-divider > div {
  padding-left: 0;
  padding-right: 0;
  padding: 0;
}

.expanded-content {
  background-color: var(--element-background-secondary);

  .row {
    align-items: top;
  }

  .row:after {
    display: block;
    content: ' ';
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  }

  .row:last-of-type:after {
    border-bottom: none;
  }
}
</style>
