<template>
  <div class="mt-4">
    <div v-if="subtargets !== undefined">
      <div
        v-for="subtarget in subtargets"
        :key="`target_form_subtarget_${subtarget.index}`"
      >
        <v-row>
          <v-col cols="11">
            <v-textarea
              v-model="subtarget.target"
              outlined
              filled
              rows="3"
              :label="subtargetLabel(subtarget.index)"
            ></v-textarea>
          </v-col>
          <v-col cols="1" class="d-flex justify-center align-top">
            <v-btn small text @click="removeSubtarget(subtarget)">
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <actions v-model="subtarget.actions"></actions>
      </div>
    </div>
    <v-col />
    <div class="d-flex justify-end" @click="addSubtarget">
      <v-btn text>
        <v-icon>mdi-plus</v-icon>
        {{ $t('targets.addSubtarget') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import actions from './dialog-actions';

import { v4 as uuid } from 'uuid';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data() {
    return {
      subtargets: []
    };
  },

  watch: {
    subtargets() {
      this.$emit('input', this.subtargets);
    },

    value() {
      this.subtargets = this.value;
    }
  },

  computed: {},

  mounted() {
    this.subtargets = this.value;
  },

  methods: {
    addSubtarget() {
      if (this.subtargets === undefined) {
        this.subtargets = [];
      }

      this.subtargets.push({
        id: uuid(),
        index: this.subtargets.length + 1,
        target: '',
        actions: [],
        subtarget: ''
      });
    },

    removeSubtarget(subtarget) {
      const itemIndex = this.subtargets.indexOf(subtarget);
      if (itemIndex < 0) {
        return;
      }

      const sortIndex = subtarget.index;

      const items = JSON.parse(JSON.stringify(this.subtargets));
      items.splice(itemIndex, 1);

      for (let i = 0; i < items.length; i++) {
        if (items[i].index >= sortIndex) {
          items[i].index--;
        }
      }

      this.subtargets = items;
    },

    subtargetLabel(num) {
      return this.$tc('targets.subtargets', 1) + ' ' + num;
    }
  },

  components: {
    actions
  }
};
</script>

<style></style>
