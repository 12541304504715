<template>
  <v-form v-model="formValid" ref="commentDialogForm">
    <v-dialog
      v-model="isVisible"
      transition="dialog-bottom-transition"
      max-width="600"
      persistent
    >
      <template v-slot:default>
        <v-card>
          <v-toolbar color="secondary">
            {{ $t('feedbacks.goToComment') }}
          </v-toolbar>
          <v-card-text class="pt-4">
            <v-textarea
              :label="$t('targets.comment')"
              v-model="comment"
              flat
              required
              row="3"
              :rules="[(v) => !!v || $t('validation.fieldRequired')]"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click.stop="saveComment">{{ $t('save') }}</v-btn>
            <v-btn text @click="closeDialog">{{ $t('close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      comment: '',
      isVisible: false,
      formValid: true
    };
  },

  watch: {
    visible() {
      this.isVisible = this.visible;
    }
  },

  methods: {
    async saveComment() {
      await this.$refs.commentDialogForm.validate();
      if (this.formValid) {
        this.$emit('save', this.comment);
        this.closeDialog();
      }
    },

    async closeDialog() {
      this.$emit('close');
      this.isVisible = false;
      this.resetDialog();
    },

    async resetDialog() {
      this.comment = '';
      await this.$refs.commentDialogForm.resetValidation();
    }
  },

  components: {}
};
</script>

<style></style>
