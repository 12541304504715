<template>
  <div>
    <div>
      <v-row v-for="action in actions" :key="`action_${action.index}`">
        <v-col cols="9" offset="2">
          <v-textarea
            v-model="action.target"
            outlined
            filled
            rows="3"
            :label="actionLabel(action.index)"
          ></v-textarea>
        </v-col>
        <v-col cols="1" class="d-flex justify-center align-top">
          <v-btn small text @click="removeAction(action)">
            <v-icon>mdi-delete-forever</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col cols="10" offset="2">
        <div class="d-flex justify-end" @click="addAction">
          <v-btn text>
            <v-icon>mdi-plus</v-icon>
            {{ $t('targets.addAction') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data() {
    return {
      actions: []
    };
  },

  watch: {
    actions() {
      this.$emit('input', this.actions);
    },

    value() {
      this.actions = this.value;
    }
  },

  mounted() {
    this.actions = this.value;
  },

  methods: {
    addAction() {
      if (this.actions === undefined) {
        this.actions = [];
      }

      this.actions.push({
        id: uuid(),
        index: this.actions.length + 1,
        target: ''
      });
    },

    removeAction(action) {
      const itemIndex = this.actions.indexOf(action);
      if (itemIndex < 0) {
        return;
      }

      const sortIndex = action.index;

      const items = [...this.actions];
      items.splice(itemIndex, 1);

      for (let i = 0; i < items.length; i++) {
        if (items[i].index >= sortIndex) {
          items[i].index--;
        }
      }

      this.actions = items;
    },

    actionLabel(num) {
      return this.$tc('targets.actions', 1) + ' ' + num;
    }
  },

  components: {}
};
</script>

<style></style>
